<template>
  <div class="comercio-container" v-if="!isLoading">
    <h1 class="comercio-nombre"> {{ comercio.nombre }}</h1>
    <p class="comercio-descripcion"> {{ comercio.descripcion  }}</p>

    <ion-grid>
      <ion-row>
        <ion-col>

          <div class="icon-text comercio-direccion">
            <ion-icon :icon="mapOutline"></ion-icon>
            <a  :href="getGoogleMapsUrl( comercio.direccion, comercio.localidad )" target="_blank">
              <span>{{ comercio.direccion }} ({{ comercio.localidad }})</span>
            </a>
          </div>

          <div class="icon-text comercio-telefono">
            <ion-icon :icon="callOutline"></ion-icon>
            <a :href="'tel:' + comercio.telefono" target="_blank">
              <span>{{ comercio.telefono  }}</span>
            </a>
            <a :href="'tel:' + comercio.telefono2" target="_blank" v-if="comercio.telefono2">
              / <span>{{ comercio.telefono2  }}</span>
            </a>

          </div>

        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-text-right">

          <ul class="comercio-social"> 
          
            <li>
              <a :href="comercio.facebook" v-if="comercio.facebook" target="_blank">
                <ion-icon :icon="logoFacebook"></ion-icon>  
              </a> 
            </li>

            <li>
              <a :href="comercio.instagram" v-if="comercio.instagram" target="_blank">
                <ion-icon :icon="logoInstagram"></ion-icon>  
              </a>
            </li>
              
            <li>
              <a :href="comercio.linkedin" v-if="comercio.linkedin" target="_blank">
                <ion-icon :icon="logoLinkedin"></ion-icon>  
              </a>           
            </li>

            <li>
              <a :href="comercio.pinterest" v-if="comercio.pinterest" target="_blank">
                <ion-icon :icon="logoPinterest"></ion-icon>  
              </a>  
            </li>

            <li>
              <a :href="comercio.tiktok" v-if="comercio.tiktok" target="_blank">
                <ion-icon :icon="logoTiktok"></ion-icon>  
              </a>           
            </li>

            <li>
              <a :href="comercio.twitter" v-if="comercio.twitter" target="_blank">
                <ion-icon :icon="logoTwitter"></ion-icon>  
              </a>
            </li>

            <li>
              <a :href="comercio.youtube" v-if="comercio.youtube" target="_blank">
                <ion-icon :icon="logoYoutube"></ion-icon>  
              </a>          
            </li>
          </ul>
        </ion-col>
      </ion-row>
    </ion-grid>

    <swiper 
      :modules="modules" 
      navigation
      :pagination="{ clickable: true }"
      >
      <swiper-slide v-for="imagen in imagenes" :key="imagen"> 
        <img :src="imagen" class="slider-img"/> 
      </swiper-slide>
    </swiper>

    <div class="action-buttons">
      <ion-button expand="block" fill="solid" size="large" class="btn-cita" @click="pedirCita">Pedir cita</ion-button>
      <ion-button expand="block" fill="outline" size="normal" class="btn-searchagain" @click="otraOptica">Seleccionar otra óptica</ion-button>
    </div>

  </div>

  <IonLoading
    :isOpen="isLoading"
    message="Cargando..."
  />
</template>

<script setup>
/* eslint-disable */
  import { ref, onBeforeMount } from 'vue'
  import { useRouter } from 'vue-router'
  import { useDatosCitaStore } from '@/store/datosCita' // Pinia store
  import { getGoogleMapsUrl, setUi, isValidParam } from '@/plugins/opttools'
  import axios from 'axios'
  import { API_URL } from '@/store/config'
  import { IonGrid, IonRow, IonCol, IonButton, IonIcon, IonLoading } from '@ionic/vue'
  import { callOutline, mapOutline, logoFacebook, logoInstagram, logoLinkedin, logoPinterest, logoTiktok, logoTwitter, logoYoutube } from 'ionicons/icons'
  import { DEFAULT_UI } from '@/store/config'
  import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';
  
  const modules = [Navigation, Pagination, A11y];

  const isLoading = ref( false );

  const router = useRouter();
  const { codigo_cuenta = "", comercio_id = "" } = router.currentRoute.value.params;

  const datosCita = useDatosCitaStore();
  datosCita.set("comercio/codigo_cuenta", codigo_cuenta.toLowerCase());
  datosCita.set("comercio/comercio_id", comercio_id);

  const comercio = ref({});
  const imagenes = ref([]);

  datosCita.resetCita();

  onBeforeMount( () => {
    if (!isValidParam(codigo_cuenta) || !isValidParam(comercio_id) || codigo_cuenta.length !== 4)  {
      router.push({ name: "error404comercio", params: { codigo_cuenta, comercio_id } });
      return;
    }
    fetchComercio();
  });

  async function fetchComercio() {
    try {
      // isLoading.value = true;
      const response = await axios.get( API_URL, {
        timeout: 10000, // 10 segundos
        params: {
          endpoint: 'comercio',
          codigo_cuenta: codigo_cuenta,
          comercio_id: comercio_id
        }
      } );
      // isLoading.value = false;
      if (response && Array.isArray(response.data) && response.data.length > 0) {
        comercio.value = response.data[0];  // just one row expected
        imagenes.value = Object.entries(comercio.value)
                              .filter(([key, value]) => key.startsWith("imagen_") && (value !== "" && value !== null) )
                              .map(([key, value]) => value);
        datosCita.sync( 'comercio', comercio.value );

        if (comercio.value.enlace_directo == 1 ) {
            const custom_ui = {
              logo: comercio.value.logo_web,
              title: 'Cita Previa',
              url_home: '/' + codigo_cuenta.toLowerCase() + '/' + comercio_id,
              color_interfaz_1: comercio.value.color_interfaz_1,
              color_interfaz_2: comercio.value.color_interfaz_2,
              color_interfaz_3: comercio.value.color_interfaz_3,
              custom_ui: 1
            }
            setUi( custom_ui );
            datosCita.sync('ui', custom_ui );
        } else {
            setUi( DEFAULT_UI );
            datosCita.sync('ui', DEFAULT_UI );
        }
      } else {
          router.push({ name: "error404comercio", params: { codigo_cuenta, comercio_id } });
      }
    } catch (error) {
      console.log(error);
      // isLoading.value = false;
      if (error.code == "ECONNABORTED") {
        alert(
          "Se ha excedido el tiempo de espera por el servidor."
        );
      }
      router.push({ name: "error404comercio", params: { codigo_cuenta, comercio_id } });
    }
  }

  function pedirCita() {
    if (comercio.value.agrupacion_servicios == 1) {
      router.push({ name: "grupos" });
    } else {
      router.push({ name: "servicios" });
    }
  }

  function otraOptica() {
    const entrada_general = sessionStorage.getItem("entrada_general")
    if(entrada_general == 1) {
      router.push({ name: "home" });
    } else {
      router.push({ name: "cuenta", params: { codigo_cuenta: codigo_cuenta.toLowerCase()}})
    }
  }
</script>

<style scoped>

.action-buttons {
  flex-direction: column;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
}

.btn-cita {
    margin-bottom: 20px;
}

.btn-searchagain {
  /* max-width: 300px; */
}

.icon-text ion-icon {
  font-size: 18px;
  margin-right: 10px;
}

.comercio-telefono span {
  
}

.slider-img {
  /*width: 100%;*/
}
.comercio-imagenes {
  display: flex;
  list-style: none;
  padding-left: 0;
  overflow: scroll;
}

.comercio-imagenes img {
  width: auto;
  height: 300px;
  margin-right: 10px;
}

.comercio-social {
  list-style: none;
  display: flex;
  justify-content: flex-end;
}
.comercio-social li ion-icon {
  font-size: 24px;
  margin-left: 5px;
  color: var(--op-color-primary);
}

.centered-image {
  max-width: 100%; /* Ensure the image doesn't overflow the slide */
  max-height: 100%; /* Ensure the image doesn't overflow the slide */
  display: block;
  margin: 0 auto; /* Center horizontally */
  position: absolute;
  top: 50%; /* Move the image down by 50% of the slide's height */
  transform: translateY(-50%); /* Adjust the image's vertical position */
}

@media (min-width: 768px) {
  .btn-cita, .btn-searchagain {
    width: 350px;
  }

}
</style>

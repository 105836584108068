<template>
    <div class="error404">
      <Error404Component />
    </div>
  </template>
  
  <script>
  
  import Error404Component from '@/components/Error404Component.vue'
  
  export default {
    name: 'Error404View',
    components: {
      Error404Component
    }
  }
  </script>
// src/store/datosCita.js
import { defineStore } from 'pinia';

export const useDatosCitaStore = defineStore('datosCita', {


  state: () => ({

    comercio: {

        codigo_cuenta: '',
        comercio_id: '',
        nombre: '',
        localidad: '',
        direccion: '',
        telefono: '',
        telefono2: '',
        descripcion: '',
        agrupacion_servicios: 0,
        seleccion_profesionales: 0,
        minimo_dias: 0,
        maximo_dias: 0,
        confirmacion_manual: 0,
        color_interfaz_1: '',
        color_interfaz_2: '',
        color_interfaz_3: '',
        imagen_1: '',
        imagen_2: '',
        imagen_3: '',
        imagen_4: '',
        imagen_5: '',
        imagen_6: '',
        imagen_7: '',
        imagen_8: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        pinterest: '',
        tiktok: '',
        twitter: '',
        youtube: '',
        enlace_directo: 0,
        logo_web: '',

        grupos: [ { 
            grupo_id: '',
            nombre: '',
            descripcion: ''
        } ],

        servicios: [ {
            servicio_id: '',
            nombre: '',
            descripcion: '',
            duracion: '',
            precio: '',
            profesionales: [{
                profesional_id: '',
                nombre: '',
                foto: '',
                horas: [ {
                    puesto_id: '',
                    fecha: '',
                    hora: ''
                }]
            }]
        }],

    },

    cita: {
        cita_id: '',
        cita_guid: '',
        nombre: '',
        apellidos: '',
        telefono: '',
        correo_e: '',
        fecha: '',
        hora: '',
        grupo_id: '',
        grupo_nombre: '',
        servicio_id: '',
        servicio_nombre: '',
        profesional_id: '',
        profesional_nombre: ''
    }, 

    ui: {
        logo: '',
        title: '',
        url_home: '',
        color_interfaz_1: '',
        color_interfaz_2: '',
        color_interfaz_3: '',
        custom_ui: 0,
    },

  }),

  getters: {

  },
  actions: {

    get( key ) {

        var value = false;

        if (typeof key === 'string') {
            
            const obj_keys = key.split("/");
            var obj = this[obj_keys[0]];

            switch( obj_keys.length ) {
                case 1:
                    value = obj;
                    break;
                case 2:
                    value = obj[obj_keys[1]];
                    if (key === 'comercio/codigo_cuenta') value = value.toLowerCase();
                    break;
                case 3:
                    value = obj[obj_keys[1]][obj_keys[2]];
                    break;
                // ...etc
            }

        }

        // TODO: error check

        return value;

    },

    set( key, value ) {

        var result = false;

        if ( value == undefined || value == null ) value = '';

        if (typeof key === 'string') {

            const obj_keys = key.split("/");

            var obj = this[obj_keys[0]];

            switch( obj_keys.length ) {
                case 1:
                    obj = value;
                    break;
                case 2:
                    obj[obj_keys[1]] = value;
                    break;
                case 3:
                    obj[obj_keys[1]][obj_keys[2]] = value;
                    break;
                // ...etc
            }

            // TODO: error check (if key exists, etc)
            result = true;

        }

        return result;

    },

    sync( key, data ) {

        if (typeof data === 'object' && data !== null) {

            Object.entries(data).forEach( ( [item_key, item_value] ) => {
                this.set( key + '/' + item_key, item_value );
            });

            return true;

        } else {

            return false;

        }

    },

    reset() {

        this.$reset; 

    },

    resetCita() {

        const objCita = {
            cita_id: '',
            cita_guid: '',
            nombre: '',
            apellidos: '',
            telefono: '',
            correo_e: '',
            fecha: '',
            hora: '',
            grupo_id: '',
            grupo_nombre: '',
            servicio_id: '',
            servicio_nombre: '',
            profesional_id: '',
            profesional_nombre: ''
        }

        this.sync( 'cita', objCita );


    }

    

  }
});
<template>

  <header v-if="logo">

    <div class="align-center">
      <router-link :to="urlHome" class="logo-link">
        <img class="logo" :src="logo" />
        <h1>{{ title }}</h1>
      </router-link>
    </div>

  </header>

</template>

<script setup>

import { ref, watch } from 'vue'
import { useDatosCitaStore } from '@/store/datosCita' // Pinia store

const datosCita = useDatosCitaStore();

const logo = ref( datosCita.get('ui/logo') );
const title = ref( datosCita.get('ui/title') );
const urlHome = ref( datosCita.get('ui/url_home') );

watch(() => datosCita.ui.logo, (newValue) => {
  logo.value = newValue;
});
watch(() => datosCita.ui.title, (newValue) => {
  title.value = newValue;
});
watch(() => datosCita.ui.url_home, (newValue) => {
  urlHome.value = newValue;
});


</script>

<style scoped>

  header {
    display: flex;
    padding: 20px;
  }

  header>div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  header .col-left {
    justify-content: flex-start;
  }

  header .col-right {
    justify-content: flex-end;
  }

  header .logo-link {
    text-align: center;
  }
  header .logo {
    max-height: 110px;
    max-width: 100%;
  }

  header h1 {
    margin-top: 10px;
    margin-left: 0;
  }
  header .icon {
    width: auto;
    height: 80px;
  }

  a.router-link-exact-active {
      color: var(--op-color-primary);
      text-decoration: none;
  }

  h1 {
    color: var(--op-color-grey);
    font-size: 28px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 20px;
  }

</style>
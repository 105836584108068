<template>
  
  <div class="infocita-container">
    <div class="datos-comercio">
      <h3 class="comercio-nombre"> {{ comercio.nombre }}</h3>

      <div class="icon-text comercio-direccion">
        <ion-icon :icon="mapOutline"></ion-icon> <a :href="getGoogleMapsUrl(comercio.direccion, comercio.localidad)" target="_blank">
          {{ comercio.direccion }} ({{ comercio.localidad }})
        </a>
      </div>

      <div class="icon-text comercio-telefono">
        <ion-icon :icon="callOutline"></ion-icon>
        <a :href="'tel:' + comercio.telefono" target="_blank">
          {{ comercio.telefono  }}
        </a>
        <a :href="'tel:' + comercio.telefono2" target="_blank" v-if="comercio.telefono2">
            / <span>{{ comercio.telefono2  }}</span>
          </a>
      </div>
    </div>

    <div class="datos-cita">
      <ion-list class="cita-fields">
      
        <ion-item v-if="cita.grupo_nombre">
            <ion-label>Grupo</ion-label> <span class="cita-field-value">{{ cita.grupo_nombre }}</span>
                <button v-if="props.mode == 'edit'" @click="irAGrupos">[Cambiar]</button>
        </ion-item>

        <ion-item v-if="cita.servicio_nombre">
            <ion-label>Servicio</ion-label> <span class="cita-field-value">{{ cita.servicio_nombre }}</span>
              <button v-if="props.mode == 'edit'" @click="irAServicios">[Cambiar]</button>
        </ion-item>

        <ion-item  v-if="cita.fecha">
            <ion-label>Fecha</ion-label> <span class="cita-field-value">{{ getUserFecha(cita.fecha) }}</span>
              <button v-if="props.mode == 'edit'" @click="irAFechas">[Cambiar]</button>
        </ion-item>

        <ion-item v-if="cita.hora">
            <ion-label>Hora</ion-label> <span class="cita-field-value">{{ cita.hora }}</span>
            <button v-if="props.mode == 'edit'" @click="irAHoras">[Cambiar]</button>
        </ion-item>

        <ion-item  v-if="cita.profesional_nombre && comercio_seleccion_profesionales">
            <ion-label>Profesional</ion-label> <span class="cita-field-value">{{ cita.profesional_nombre }}</span>
            <button v-if="props.mode == 'edit'" @click="irAInfoPersonal">[Cambiar]</button>
        </ion-item>

        <div class="personal-fields" v-if="cita.nombre">
          <ion-item>
              <ion-label>Nombre</ion-label> <span class="cita-field-value">{{ cita.nombre }}</span>
              <button v-if="props.mode == 'edit'" @click="irAInfoPersonal">[Cambiar]</button>
          </ion-item>

          <ion-item>
              <ion-label>Apellidos</ion-label> <span class="cita-field-value">{{ cita.apellidos }}</span>
              <button v-if="props.mode == 'edit'" @click="irAInfoPersonal">[Cambiar]</button>
          </ion-item>

          <ion-item>
              <ion-label>Teléfono</ion-label> <span class="cita-field-value">{{ cita.telefono }}</span>
              <button v-if="props.mode == 'edit'" @click="irAInfoPersonal">[Cambiar]</button>
          </ion-item>

          <ion-item>
              <ion-label>Email</ion-label> <span class="cita-field-value">{{ cita.correo_e }}</span>
              <button v-if="props.mode == 'edit'" @click="irAInfoPersonal">[Cambiar]</button>
          </ion-item>
        </div>
      </ion-list>
    </div>
  </div>

</template>
  
<script setup>
/* eslint-disable */
    import { ref, defineProps } from 'vue'
    import { useRouter } from 'vue-router'
    import { useDatosCitaStore } from '@/store/datosCita'
    import { getGoogleMapsUrl } from '@/plugins/opttools'
    import { IonItem, IonList, IonIcon, IonLabel } from '@ionic/vue'
    import { callOutline, mapOutline } from 'ionicons/icons'

    const router = useRouter();

    const datosCita = useDatosCitaStore();

    const props = defineProps({ 
      mode: { type: String, default: 'edit' }
    });

    const comercio = ref(datosCita.get('comercio'));
    const cita = ref(datosCita.get('cita'));
    const comercio_seleccion_profesionales = ref(datosCita.get('comercio/seleccion_profesionales'));

    if ( cita.value.grupo_nombre == '' ) cita.value.grupo_nombre = cita.value.grupo_id;
    if ( cita.value.servicio_nombre == '' ) cita.value.servicio_nombre = cita.value.servicio_id;
    if ( cita.value.profesional_nombre == '' ) cita.value.profesional_nombre = cita.value.profesional_id;

    function irAGrupos() {
      router.push({ name: 'grupos', 
                    params: { codigo_cuenta: comercio.value.codigo_cuenta.toLowerCase(), 
                              comercio_id: comercio.value.comercio_id } })
    }

    function irAServicios() {
      router.push({ name: 'servicios', 
                    params: { codigo_cuenta: comercio.value.codigo_cuenta.toLowerCase(), 
                    comercio_id: comercio.value.comercio_id } })
    }

    function irAFechas() {
      router.push({ name: 'fechas', 
                    params: { codigo_cuenta: comercio.value.codigo_cuenta.toLowerCase(), 
                    comercio_id: comercio.value.comercio_id } })
    }

    function irAHoras() {
      router.push({ name: 'horas', 
                    params: { codigo_cuenta: comercio.value.codigo_cuenta.toLowerCase(), 
                              comercio_id: comercio.value.comercio_id } })
    }

    function irAInfoPersonal() {
      router.push({ name: 'infopersonal', 
                    params: { codigo_cuenta: comercio.value.codigo_cuenta.toLowerCase(), 
                              comercio_id: comercio.value.comercio_id } })
    }

    function getUserFecha (fecha) {
      fecha = new Date( fecha );
      return  fecha.getDate() + '/' + (fecha.getMonth()+1) + '/' + fecha.getFullYear();
    }
</script>
  
<style scoped>
  .infocita-container {
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
  }

  .infocita-container p {
    margin: 0;
  }

  .icon-text ion-icon {
    font-size: 18px;
    margin-right: 10px;
  }

  .datos-comercio {
    margin-bottom: 10px;
  }

  .comercio-nombre {
    margin-bottom: 10px;
  }

  ion-grid {
    padding: 0;
  }

  .btn-edit {
    margin-left: 10px;
    color: var(----op-color-primary);
  }

  ion-item {
    --inner-padding-bottom: 0;
    --inner-padding-top: 0;
  }

  .cita-field-value {
    font-weight: 500;
  }

  .btn-edit {
    font-size: 14px;
    margin-left: 10px;
  }

  .cita-fields button {
    width: 70px;
    height: 2em;
    margin-left: 5px;
    padding: 0px;
    color: var(--op-color-primary);
    background-color: transparent;
    border-color: var(--op-color-primary);
    border-width: 0px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
  }
</style>
  
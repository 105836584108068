<template>

  <InfoCitaComponent />

  <div class="grupos-servicios">

    <div class="message-box">
      <ion-icon :icon="informationCircle"></ion-icon>
      <p>Seleccione un servicio</p>
    </div>

    <ul class="servicios items">
      <li v-for="servicio in servicios" :key="servicio.servicio_id">
        <ion-item button detail="true" class="item-servicio" @click="setServicio( servicio )">
          <ion-label>
            <h3>{{ servicio.nombre }}</h3>
            <p>{{ servicio.descripcion }}</p>
            <p v-if="servicio.duracion || servicio.precio">
              <span v-if="servicio.duracion"><ion-icon :icon="timeOutline"></ion-icon> {{  servicio.duracion }}</span> 
              <span v-if="servicio.precio"><ion-icon :icon="pricetagOutline"></ion-icon> {{  servicio.precio }}</span> 
            </p>
          </ion-label>
        </ion-item>
      </li>
    </ul>
    
  </div>

  <IonLoading
    :isOpen="isLoading"
    message="Cargando..."
  />


</template>

<script setup>

/* eslint-disable */

  // Framework & Tools
  import { ref, onBeforeMount, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { useDatosCitaStore } from '@/store/datosCita' // Pinia store

  import InfoCitaComponent from '@/components/InfoCitaComponent.vue' 

  import axios from 'axios'

  import { isValidParam } from '@/plugins/opttools'

  // API
  import { API_URL } from '@/store/config'

  // UX
  import { IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonIcon, IonLoading } from '@ionic/vue'
  import { informationCircle, timeOutline, pricetagOutline } from 'ionicons/icons'

  const router = useRouter();

  const irAServicio = { name: 'servicios', params: { page: 'servicio' }}
  
  const datosCita = useDatosCitaStore();
  const codigo_cuenta = datosCita.get("comercio/codigo_cuenta");
  const comercio_id = datosCita.get("comercio/comercio_id");

  const isLoading = ref( false );

  const servicios = ref({});

  const currentGrupo = ref( { grupo_id: datosCita.get('cita/grupo_id'), grupo_nombre: datosCita.get('cita/grupo_nombre')} );

  onBeforeMount( () => {
    fetchServicios();
  });

  async function fetchServicios() {
    try {
      // isLoading.value = true;
      const response = await axios.get( API_URL, {
        timeout: 10000, // 10 segundos
        params: {
          endpoint: 'servicios',
          codigo_cuenta: codigo_cuenta,
          comercio_id: comercio_id,
          grupo_id: currentGrupo.value.grupo_id
        }
      } );
      if ( Array.isArray(response.data) ) {
        servicios.value = response.data.map( ({ servicio_id, nombre, descripcion, duracion, precio }) => ({ servicio_id, nombre, descripcion, duracion, precio }) );
        datosCita.sync( 'comercio/servicios', servicios.value );
        // isLoading.value = false;
      }
    } catch (error) {
      console.log(error);
      // isLoading.value = false;
      if (error.code == "ECONNABORTED") {
        alert(
          "Se ha excedido el tiempo de espera por el servidor."
        );
      }
    }
  }

  function setServicio(servicio) {
      datosCita.set( 'cita/servicio_id', servicio.servicio_id );
      datosCita.set( 'cita/servicio_nombre', servicio.nombre );
      const irAFechas = { name: 'fechas', 
                  params: { codigo_cuenta: codigo_cuenta, 
                            comercio_id: comercio_id, 
                            page: 'dia',
                            servicio_id: servicio.servicio_id,
                            servicio_nombre: servicio.nombre }}
      router.push(irAFechas);
  }
</script>

<style scoped>
 
 .item-grupo button.item-native {
    padding-left: 0 !important;
 }

 .item-grupo h3,
 .item-servicio h3 {
    font-size: 18px;
    font-weight: bold;
    color: var(--op-color-primary);
 }

 .item-grupo p,
 .item-servicio p {
    font-size: 16px;
    color: var(--op-color-grey);
 }

 .item-servicio span {
    margin-right: 10px;
 }

 .item-grupo ion-label {
    white-space: normal;
 }

 .item-servicio ion-label {
    white-space: normal;
 }

</style>
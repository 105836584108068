<template>
  <div class="servicios">
    <ServiciosComponent />
  </div>
</template>

<script>

import ServiciosComponent from '@/components/ServiciosComponent.vue'

export default {
  name: 'ServiciosView',
  components: {
    ServiciosComponent
  }
}
</script>

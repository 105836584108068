<template>

<div class="politica-privacidad-container">
        <h1>Política de privacidad</h1>

<p>Fecha de vigencia: 19 de octubre de 2023</p>

<p>Esta política de privacidad describe cómo OPTIPLUS SOFTWARE S.L. recopila, utiliza y protege la información personal que usted proporciona a través de nuestro sitio web y servicios relacionados. Respetamos su privacidad y estamos comprometidos a proteger sus datos personales de acuerdo con el Reglamento General de Protección de Datos (RGPD) y las leyes aplicables en España.</p>

<h2>Información que recopilamos</h2>

<p>Recopilamos la siguiente información personal que usted nos proporciona al utilizar nuestro sitio web:</p>

<ul>
    <li>Nombre y apellidos</li>
    <li>Teléfono</li>
    <li>Correo electrónico</li>
    <li>IP</li>
</ul>

<h2>Cómo utilizamos su información</h2>

<p>Utilizamos la información recopilada para programar la cita y para que la óptica pueda comunicarse con usted.</p>

<ul>
    <li>Nombre y apellidos: Para que la óptica pueda identificar su cita.</li>
    <li>Teléfono: Para que la óptica pueda ponerse en contacto con usted, así como enviarle SMS de recordatorio de cita.</li>
    <li>Correo electrónico: Para enviarle correos de cita creada o modificada, con el enlace que le permite gestionar la cita, así como correos de cita anulada. Para que la óptica pueda ponerse en contacto con usted, así como enviarle correos de recordatorio de cita.</li>
    <li>IP: Por motivos de seguridad del sistema.</li>
</ul>

<h2>Consentimiento</h2>

<p>Al proporcionar su información personal, usted otorga su consentimiento para que la utilicemos con los propósitos mencionados anteriormente. Si en algún momento decide retirar su consentimiento, comuníquese con nosotros utilizando la información de contacto que se le proporciona en este documento. Tenga en cuenta que la retirada del consentimiento no afectará la legalidad del procesamiento previo a la retirada.</p>

<h2>Divulgación a terceros</h2>

<p>Sólo  compartiremos su información personal con la óptica a la cual usted decide solicitar la cita. No compartiremos su información personal con terceros, excepto cuando sea necesario para cumplir con obligaciones legales o reglamentarias.</p>

<h2>Seguridad de los datos</h2>

<p>Implementamos medidas de seguridad adecuadas para proteger su información personal y prevenir el acceso no autorizado o el uso indebido.</p>

<h2>Sus derechos</h2>

<p>De acuerdo con el RGPD, usted tiene los siguientes derechos con respecto a su información personal:</p>

<ul>
    <li>Derecho de acceso: Tiene derecho a acceder a la información personal que tenemos sobre usted.</li>
    <li>Derecho de rectificación: Puede solicitar la corrección de información personal inexacta.</li>
    <li>Derecho de supresión: Puede solicitar la eliminación de su información personal.</li>
    <li>Derecho a la portabilidad de datos: Tiene derecho a recibir sus datos personales en un formato estructurado y de uso común.</li>
</ul>

<p>Para ejercer cualquiera de estos derechos o para realizar cualquier consulta relacionada con su información personal, comuníquese con nosotros utilizando la información de contacto que se proporciona en este documento.</p>

<h2>Cambios a esta política</h2>

<p>Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Las versiones actualizadas se publicarán en nuestro sitio web con la fecha de vigencia revisada. Le recomendamos que consulte esta política regularmente para mantenerse informado sobre cómo estamos protegiendo su información.</p>

<h2>Información de contacto</h2>

<p>Si tiene alguna pregunta o inquietud relacionada con esta Política de Privacidad o el manejo de su información personal, no dude en ponerse en contacto con nosotros a través de lo siguientes medios:</p>

<p>
OPTIPLUS SOFTWARE S.L.<br>
B44724680<br>
C/ Félix del Río, nº 33 pta. 24<br>
46025 Valencia (España)<br>
+34 963 471 380<br>
optiplus@optiplus.es
</p>

</div>

  </template>
  
  <script>
  
  </script>
  
  <style scoped>
  
  .politica-privacidad-container {
    padding: 20px;
    margin-bottom: 40px;
  }

  </style>
<template>

  <InfoCitaComponent />

  <div class="grupos-servicios">

    <div class="message-box">
      <ion-icon :icon="informationCircle"></ion-icon>
      <p>Seleccione un grupo de servicios</p>
    </div>

    <ul class="grupos items">
      <li v-for="grupo in grupos" :key="grupo.grupo_id">
        <ion-item button detail="true" class="item-grupo" @click="setGrupo(grupo);">
          <ion-label>
            <h3>{{ grupo.nombre }}</h3>
            <p>{{ grupo.descripcion }}</p>
          </ion-label>
        </ion-item>
      </li>
    </ul>
    
  </div>

  <IonLoading
    :isOpen="isLoading"
    message="Cargando..."
  />

</template>

<script setup>

/* eslint-disable */

  // Framework & Tools
  import { ref, onBeforeMount, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { useDatosCitaStore } from '@/store/datosCita' // Pinia store

  import InfoCitaComponent from '@/components/InfoCitaComponent.vue' 

  import axios from 'axios'

  // API
  import { API_URL } from '@/store/config'

  // UX
  import { IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonIcon, IonLoading } from '@ionic/vue'
  import { informationCircle, timeOutline, pricetagOutline } from 'ionicons/icons'

  const router = useRouter();
  
  const datosCita = useDatosCitaStore();
  const codigo_cuenta = datosCita.get("comercio/codigo_cuenta");
  const comercio_id = datosCita.get("comercio/comercio_id");

  const isLoading = ref( false );

  const grupos = ref({});

  onBeforeMount( () => { 
    fetchGrupos();
  });

  async function fetchGrupos() {
    try {
      // isLoading.value = true;
      const response = await axios.get( API_URL, {
        timeout: 10000, // 10 segundos
        params: {
          endpoint: 'grupos',
          codigo_cuenta: codigo_cuenta,
          comercio_id: comercio_id
        }
      } );
      if ( Array.isArray(response.data) ) {
        grupos.value = response.data.map( ({ grupo_id, nombre, descripcion }) => ({ grupo_id, nombre, descripcion }) );
        datosCita.sync( 'comercio/grupos', grupos.value );
      }
      // isLoading.value = false;
    } catch (error) {
      console.log(error);
      // isLoading.value = false;
      if (error.code == "ECONNABORTED") {
        alert(
          "Se ha excedido el tiempo de espera por el servidor."
        );
      }
    }
  }

  const setGrupo = (grupo) => {
    if ( grupo == undefined || grupo == '' || grupo == {} || grupo == null ) grupo = { grupo_id: '', grupo_nombre: ''};
    datosCita.set( 'cita/grupo_id', grupo.grupo_id );
    datosCita.set( 'cita/grupo_nombre', grupo.nombre );
    router.push({ name: 'servicios' });
  }

</script>

<style scoped>
 
 .item-grupo button.item-native {
    padding-left: 0 !important;
 }

 .item-grupo h3,
 .item-servicio h3 {
    font-size: 18px;
    font-weight: bold;
    color: var(--op-color-primary);
 }

 .item-grupo p,
 .item-servicio p {
    font-size: 16px;
    color: var(--op-color-grey);
 }

 .item-servicio span {
    margin-right: 10px;
 }

 .item-grupo ion-label {
    white-space: normal;
 }

 .item-servicio ion-label {
    white-space: normal;
 }

</style>
<template>
  <div class="fechas">
    <HorasComponent />
  </div>
</template>

<script>

import HorasComponent from '@/components/HorasComponent.vue'

export default {
  name: 'HorasView',
  components: {
    HorasComponent
  }
}
</script>

<template>
    <div class="error404">
        <h1>Error 404</h1>
        <p>No se pudo encontrar la página</p>
    </div>
  </template>
  
  <script setup>
    import { useDatosCitaStore } from '@/store/datosCita' // Pinia store
    import { DEFAULT_UI } from '@/store/config'
    import { setUi } from '@/plugins/opttools'

    const datosCita = useDatosCitaStore();
    datosCita.sync('ui', DEFAULT_UI );
    setUi( DEFAULT_UI );
  </script>
  
  <style scoped>

    .error404 {
        text-align: center;
        box-shadow: 0 0 10px 5px rgb(230, 230, 230);
        border: none;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 8px;
    }
    .error404 h1 {
        color: black;
        font-size: 28px;
        font-weight: 800;
    }
    .error404 p {
        color: black;
        font-size: 20px;
    }

  </style>
<template>
  <div class="info-personal">
    <InfoPersonalComponent />
  </div>
</template>

<script>

import InfoPersonalComponent from '@/components/InfoPersonalComponent.vue'

export default {
  name: 'InfoPersonal',
  components: {
    InfoPersonalComponent
  }
}
</script>

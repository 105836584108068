import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ComerciosView from '../views/ComerciosView.vue'
import CuentaView from '../views/CuentaView.vue'
import ComercioView from '../views/ComercioView.vue'
import GruposView from '../views/GruposView.vue'
import ServiciosView from '../views/ServiciosView.vue'
import FechasView from '../views/FechasView.vue'
import HorasView from '../views/HorasView.vue'
import InfoPersonalView from '../views/InfoPersonalView.vue'
import ResumenView from '../views/ResumenView.vue'
import ActualizarCitaView from '../views/ActualizarCitaView.vue'
import Error404View from '../views/Error404View.vue'

const routes = [
  //--> formas de entrar a la aplicación
  {
    name: 'actualizarcita',
    path: '/:codigo_cuenta/:cita_id/:token',
    component: ActualizarCitaView
  },
  {
    name: 'home',
    path: '/',
    component: HomeView,
  },
  {
    name: 'cuenta',
    path: '/:codigo_cuenta',
    component: CuentaView,
  },
  {
    name: 'enlace-directo',
    path: '/:codigo_cuenta/:comercio_id',
    component: ComercioView,
  },
  //--< formas de entrar a la aplicación
  {
    name: 'comercios',
    path: '/',
    component: ComerciosView
  },
  {
    name: 'comercio',
    path: '/:codigo_cuenta/:comercio_id',
    component: ComercioView,
  },
  {
    name: 'grupos',
    path: '/:codigo_cuenta/:comercio_id',
    component: GruposView
  },
  {
    name: 'servicios',
    path: '/:codigo_cuenta/:comercio_id',
    component: ServiciosView
  },
  {
    name: 'fechas',
    path: '/:codigo_cuenta/:comercio_id',
    component: FechasView
  },
  {
    name: 'horas',
    path: '/:codigo_cuenta/:comercio_id',
    component: HorasView
  },
  {
    name: 'infopersonal',
    path: '/:codigo_cuenta/:comercio_id',
    component: InfoPersonalView
  },
  {
    name: 'resumen',
    path: '/:codigo_cuenta/:comercio_id',
    component: ResumenView
  },
  {
    name: 'error404cuenta',
    path: '/:codigo_cuenta',
    component: Error404View
  },
  {
    name: 'error404comercio',
    path: '/:codigo_cuenta/:comercio_id',
    component: Error404View
  },
  {
    name: 'error404cita',
    path: '/:codigo_cuenta/:cita_id/:token',
    component: Error404View
  },

  /* DEFAULT, 404 ROUTE -> HOME */
  // {
  //   path: '/:pathMatch(.*)*',
  //   redirect: { name: 'error404' },
  // },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

<template>
  <div class="info-personal">
    <ActualizarCitaComponent />
  </div>
</template>

<script>

import ActualizarCitaComponent from '@/components/ActualizarCitaComponent.vue'

export default {
  name: 'ActualizarCita',
  components: {
    ActualizarCitaComponent
  }
}
</script>

<template>

  <InfoCitaComponent />

  <div class="fecha-hora">

    <div class="message-box">
      <ion-icon :icon="informationCircle"></ion-icon>
      <p>Seleccione el día de su cita</p>
    </div>

    <div class="fecha calendar">
       
      <ion-datetime 
        class="datetime"
        :value="getParamFecha(fecha)"
        :first-day-of-week="1"
        :min="getParamFecha(fecha_min)"
        :max="getParamFecha(fecha_max)"
        locale="es-ES"
        presentation="date"
        :prefer-wheel="false"
        size="cover"
        @ionChange="onDateChange"
      ></ion-datetime>

    </div>

  </div>

  <!--<IonLoading
      :isOpen="isLoading"
      message="Cargando..."
    />-->

</template>

<script setup>

/* eslint-disable */

  // Framework & Tools
  import { ref, onBeforeMount, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { useDatosCitaStore } from '@/store/datosCita' // Pinia store

  import InfoCitaComponent from '@/components/InfoCitaComponent.vue' 

  // import axios from 'axios' 

  // import { isValidParam } from '@/plugins/opttools'

  // API
  // import { API_URL } from '@/store/config'

  // UX
  import { IonDatetime, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonIcon, IonLoading } from '@ionic/vue'
  import { informationCircle } from 'ionicons/icons'

  const router = useRouter();

  const datosCita = useDatosCitaStore();

  // const isLoading = ref( false );

  const fecha = ref( {} );

  const cita_fecha = datosCita.get('cita/fecha');
  if (cita_fecha == '') {
    fecha.value = new Date();
  } else {
    fecha.value = cita_fecha;
  }

  let fecha_min = new Date();
  fecha_min.setDate(fecha_min.getDate() + datosCita.get('comercio/minimo_dias'));

  let fecha_max = new Date();
  fecha_max.setDate(fecha_max.getDate() + datosCita.get('comercio/maximo_dias'));

  // params
  // const codigo_cuenta = datosCita.get('comercio/codigo_cuenta');
  // const comercio_id = datosCita.get('comercio/comercio_id');
  // const servicio_id = datosCita.get('cita/servicio_id');
  // const servicio_nombre = datosCita.get('cita/servicio_nombre');
  // const profesional_id = datosCita.get('cita/profesional_id');
  // const profesional_nombre = datosCita.get('cita/profesional_nombre');

  // agrupar profesionales?
  // const comercio_seleccion_profesionales = ref( datosCita.get('comercio/seleccion_profesionales') );

  function onDateChange(event) {
    fecha.value = new Date(event.detail.value);
    datosCita.set('cita/fecha', getParamFecha(fecha.value) );
    router.push({ name: 'horas' });
  }

  function getParamFecha(fecha) {
    fecha = new Date(fecha);
    const year = fecha.getFullYear();
    const month = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
    const day = fecha.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // function getUserFecha(fecha) {
  //   fecha = new Date(fecha);
  //   return  fecha.getDate() + '/' + (fecha.getMonth()+1) + '/' + fecha.getFullYear();
  // };

</script>

<style scoped>
 
ion-datetime.datetime {
  --background: #ffffff;
}



ion-avatar {
    --border-radius: 10px;
    padding-bottom: 10px;
  }

.profesional-item {
    margin: 10px 0;
}

.profesional ion-label {
  font-size: 18px;
  font-weight: bold;
  margin-left: 20px;
}

ion-avatar {
  width: 80px;
  height: 80px;
}

.calendar-day {
  cursor: pointer;
}

.horas {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.horas li {
  padding: 5px;
}



.hora-item ion-button {
  --padding-start: 2em;
  --padding-end: 2em; 
  font-weight: bold;
  font-size: 14px;
}

</style>
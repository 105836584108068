<template>
  <h2>Buscar ópticas</h2>

  <p>Introduzca una dirección postal, localidad o nombre de óptica y pulse el botón "Buscar ópticas" para encontrar aquellas que mejor se ajustan a la búsqueda. O pulse el botón "Buscar cerca de mí" para encontrar las más cercanas a su ubicación.</p>

  <ion-searchbar placeholder="Dirección, localidad o nombre..." v-model="direccion" @keyup.enter="geocodeAddress"></ion-searchbar>

  <div class="action-buttons">
    <ion-button expand="block" size="large" class="btn-geocodeAddress" @click="geocodeAddress">Buscar ópticas</ion-button>
    <ion-button @click="geocodeBrowser" class="btn-geocodeBrowser" expand="block" fill="outline"><ion-icon :icon="navigateOutline"></ion-icon> &nbsp; Busca cerca de mí</ion-button>
  </div>

  <IonLoading
    :isOpen="isSearching"
    message="Buscando..."
  />

  <div v-if="!isSearching">
    <ul class="cards comercios">
      <li v-for="comercio in paginatedComercios" :key="comercio.nombre" @click="goToComercio( comercio.codigo_cuenta, comercio.comercio_id )">
        <div class="title">{{ comercio.nombre }}</div>
        <div class="subtitle">{{ comercio.localidad }}</div>
        <div class="direccion">{{ comercio.direccion }}</div>
        <div class="description">{{ mToKm(comercio.distancia_m) }} km </div>
      </li>
    </ul>

    <p v-if="comercios != null && comercios.length == 0 && currentPage > 0" >No se han encontrado ópticas cercanas a esa dirección.</p>

    <div class="action-buttons">
      <ion-button fill="outline" expand="block" class="btn-loadmore" @click="loadMore" v-if="showLoadMoreButton && comercios.length > 0">Cargar más...</ion-button>
    </div>
  </div>

</template>

<script setup>
  import { ref, computed, onBeforeMount, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { useDatosCitaStore } from '@/store/datosCita' // Pinia store
  import axios from 'axios'
  import { setUi } from "@/plugins/opttools";
  import { API_URL, GEOCODE_URL, DEFAULT_UI } from '@/store/config'
  import { IonButton, IonIcon, IonSearchbar, IonLoading } from '@ionic/vue'
  import { navigateOutline } from 'ionicons/icons'

  const router = useRouter();

  const datosCita = useDatosCitaStore();

  const comercios = ref([]);
  const direccion = ref( sessionStorage.getItem( 'direccion' ) );
  const coords = ref( {latitud:'', longitud:''} );
  const max_resultados = 30;
  const itemsPerPage = 5;
  const currentPage = ref(0);

  const isSearching = ref(false);
  
  const paginatedComercios = computed(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return comercios.value.slice(0, endIndex);
  });

  watch(paginatedComercios, () => {
    isSearching.value = false;
  })

  const showLoadMoreButton = computed(() => {
      return currentPage.value * itemsPerPage < comercios.value.length;
  });

  onBeforeMount(() => {
    datosCita.$reset();
    datosCita.sync('ui', DEFAULT_UI);
    setUi( DEFAULT_UI );
  });

  function loadMore() {
      currentPage.value++;
  }

  async function geocodeAddress() {
    isSearching.value = true;
    const geocodeUrl = GEOCODE_URL + direccion.value;
    try {
      const response = await fetch( geocodeUrl );
      if ( !response.ok ) {
        alert( 'No se ha podido geolocalizar la dirección, se mostrarán las ópticas más cercanas a su localización.');
        isSearching.value = false;
        geocodeBrowser();
        return false;
      }
      const data = await response.json();
      if ( data.length > 0 ) {
        coords.value.latitud = data[0].lat;
        coords.value.longitud = data[0].lon;
        if ( direccion.value == null ) direccion.value = '';
        isSearching.value = false;
        fetchComercios();
      } else {
        alert( 'No se han obtenido resultados, se mostrarán las ópticas más cercanas a su localización.');
        isSearching.value = false;
        geocodeBrowser();
      }
    } catch (error) {
      isSearching.value = false;
      clearCoords();
      console.error(error);
    }
  }

  function geocodeBrowser() {
    isSearching.value = true;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          coords.value.latitud = position.coords.latitude;
          coords.value.longitud = position.coords.longitude;
          isSearching.value = false;
          fetchComercios();
        },
        (error) => {
          isSearching.value = false;
          clearCoords();
          console.log('GeoLocation:', error);
          alert( 'No se ha autorizado la geolocalización. Por favor, utilice el buscador por direcciones.')
        }
      );
    } else {
      isSearching.value = false;
      alert( 'No se ha podido obtener su localización.')
    }
  }

  function clearCoords() {
      coords.value.latitud = 'null';
      coords.value.longitud = 'null';
  }

  function mToKm(m) {
    if ( isNaN(m) ) m = 0;
    const km = m / 1000;
    return km.toFixed(2);
  }

  async function fetchComercios() {
    isSearching.value = true;
    try {
      if ( coords.value.latitud == '' && coords.value.longitud == '' ) {
        coords.value = { latitud: 'null', longitud: 'null' }; //default???
      }
      const response = await axios.get( API_URL, {
        timeout: 10000, // 10 segundos
        params: {
          endpoint: 'comercios',
          codigo_cuenta: '',
          texto: direccion.value,
          latitud: coords.value.latitud,
          longitud: coords.value.longitud,
          max_resultados: max_resultados
        }
      } );
      if ( Array.isArray(response.data) ) {
        comercios.value = response.data;
      } else {
        comercios.value = [];
      }
      currentPage.value = 1; 
    } catch (error) {
      console.log(error);
      if (error.code == "ECONNABORTED") {
        isSearching.value = false;
        alert(
          "Se ha excedido el tiempo de espera por el servidor."
        );
      }
    }
  }

  function goToComercio(codigo_cuenta, comercio_id) {
    datosCita.set( 'comercio/codigo_cuenta', codigo_cuenta );
    datosCita.set( 'comercio/comercio_id', comercio_id );
    router.push({ name: 'comercio', params: { codigo_cuenta: codigo_cuenta.toLowerCase(), comercio_id } });
  }

</script>

<style scoped>
  ul {
    text-align: left;
  }

  .action-buttons {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .btn-geocodeAddress {
    margin-bottom: 20px;
  }

  .searchbar-input.sc-ion-searchbar-md {
    padding-left: 45px !important;
    padding-right: 20px !important;
  }

  ion-searchbar input {
    padding-left: 45px !important;
    padding-right: 20px !important;
  }

  .search-nearme {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }

  .search-nearme label {
      margin-right: 10px;
    }


  @media (min-width: 768px) {

    .search-nearme label {
      display: none;
    }

    .btn-geocodeAddress,
    .btn-geocodeBrowser {
      max-width: 300px;
    }

  }
</style>

<template>
  <div class="resumen-cita">
    <ResumenComponent />
  </div>
</template>

<script>

import ResumenComponent from '@/components/ResumenComponent.vue'

export default {
  name: 'ResumenView',
  components: {
    ResumenComponent
  }
}
</script>

<template>
  <footer>
    <p>
      <a href="https://optiplus.es/" target="_blank">&copy; Optiplus Software</a>
      <br>
      <sub>Versión 0.1.9</sub>
    </p>
  </footer>
</template>

<script setup>

  
</script>

<style scoped>

  footer {
    background-color: var(--op-app-background);
    padding: 20px;
    border-top: 1px solid #ccc;
    margin-top: 40px;
  }

  p {
    color: #666;
    font-size: 16px;
  }

</style>
// opttools.js
// Generic global functions and tools

import { GOOGLEMAPS_URL } from '@/store/config';
import { useDatosCitaStore } from '@/store/datosCita' // Pinia store

/* eslint-disable */

export const getGoogleMapsUrl = function ( address, location ) {

    return GOOGLEMAPS_URL + encodeURIComponent(address) + "+" + encodeURIComponent(location);

}


export const getColorInterfaz = ( color_interfaz ) => {
      
    color_interfaz = color_interfaz.toString();

    if ( isValidRgbCode(color_interfaz) ) {
      const r = parseInt(color_interfaz.substring(0, 3));
      const g = parseInt(color_interfaz.substring(3, 6));
      const b = parseInt(color_interfaz.substring(6, 9));

      return `rgb(${r}, ${g}, ${b.toString().padStart(3, '0')})`;

    } else {

      return '#000000';

    }


  }

  export const isValidRgbCode = ( color_interfaz ) => {

    color_interfaz = color_interfaz.toString();

    if ( color_interfaz.length !== 9) {
      return false;
    }

    const r = parseInt(color_interfaz.substring(0, 3));
    const g = parseInt(color_interfaz.substring(3, 6));
    const b = parseInt(color_interfaz.substring(6, 9));

    return (
      !isNaN(r) &&
      !isNaN(g) &&
      !isNaN(b) &&
      r >= 0 &&
      r <= 255 &&
      g >= 0 &&
      g <= 255 &&
      b >= 0 &&
      b <= 255
    );
  };

  export const setUi = ( custom_ui ) => {

    // Converts RGB coded values to valid hex color values
    const datosCita = useDatosCitaStore();

    if ( isValidRgbCode(custom_ui.color_interfaz_1) ) {
      document.documentElement.style.setProperty("--op-color-primary", getColorInterfaz(custom_ui.color_interfaz_1));
      document.documentElement.style.setProperty("--ion-color-primary", getColorInterfaz(custom_ui.color_interfaz_1));
    }
    
    if ( isValidRgbCode(custom_ui.color_interfaz_2) ) {
        document.documentElement.style.setProperty("--op-color-secondary", getColorInterfaz(custom_ui.color_interfaz_2));
        document.documentElement.style.setProperty("--ion-color-secondary", getColorInterfaz(custom_ui.color_interfaz_2));
    }
        
    if ( isValidRgbCode(custom_ui.color_interfaz_3) ) {
        document.documentElement.style.setProperty("--op-app-backdrop", getColorInterfaz(custom_ui.color_interfaz_3));
    }


    // save to store
    datosCita.sync('ui', custom_ui );


  }


  export const isValidParam = ( s ) => {

    if ( s === null || s === undefined ) return false;

    if ( s === '' || s === 'null' || s === 'undefined' ) return false;

    return true;

  }

  export const canGetComercio = ( codigo_cuenta, comercio_id ) => {

        
    if (  !isValidParam(codigo_cuenta) || !isValidParam(comercio_id) ) {

      // persistance
      // codigo_cuenta = sessionStorage.getItem('codigo_cuenta');
      // comercio_id = sessionStorage.getItem('comercio_id');

      if (  isValidParam(codigo_cuenta) && isValidParam(comercio_id) ) {
          return true;
      } else {
          return false;
      }

    }


  }
<template>

  <InfoCitaComponent />

  <div class="fecha-hora">

    <div class="message-box">
      <ion-icon :icon="informationCircle"></ion-icon>
      <p>Seleccione la hora de su cita</p>
    </div>

    <!-- profesionales & horas -->
    <ul class="profesionales items" v-if="comercio_seleccion_profesionales">

      <li class="profesional-item" v-for="profesional in profesionales" :key="profesional.profesional_id">

        <ion-item class="profesional">
          <ion-avatar v-if="profesional.foto">
            <img :alt="profesional.nombre" :src="profesional.foto" />
          </ion-avatar>
          <ion-label> {{ profesional.nombre }} </ion-label>
        </ion-item>

        <ul class="horas items">
          <li v-if="!profesional.horas || profesional.horas.length == 0">No hay horas disponibles en esta fecha</li>
          <li class="hora-item" v-for="slot in profesional.horas" :key="slot.hora">
            <ion-button :class="{ 'cita-hora': slot.hora === hora && profesional.profesional_id === profesional_id }" size="small" fill="outline" @click="setTime( slot.hora, profesional )"> {{ slot.hora }} </ion-button>
          </li>
        </ul>
      </li>

    </ul>

    <!-- solo horas -->
    <ul class="horas items" v-if="!comercio_seleccion_profesionales">
      <li v-if="horas_disponibles.length == 0">No hay horas disponibles en esta fecha</li>
      <li class="hora-item" v-for="slot in horas_disponibles" :key="slot.hora">
        <ion-button :class="{ 'cita-hora': slot.hora === hora }" size="small" fill="outline" @click="setTime( slot.hora, { profesional_id: slot.profesional_id, profesional_nombre: ''} )"> {{ slot.hora }} </ion-button>
      </li>
    </ul>

  </div>

  <!--<IonLoading
      :isOpen="isLoading"
      message="Cargando..."
    />-->

</template>

<script setup>

/* eslint-disable */

  // Framework & Tools
  import { ref, onBeforeMount, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { useDatosCitaStore } from '@/store/datosCita' // Pinia store

  import InfoCitaComponent from '@/components/InfoCitaComponent.vue' 

  import axios from 'axios' 

  import { isValidParam } from '@/plugins/opttools'

  // API
  import { API_URL } from '@/store/config'

  // UX
  import { IonDatetime, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonIcon, IonLoading } from '@ionic/vue'
  import { informationCircle } from 'ionicons/icons'

  const router = useRouter();

  const datosCita = useDatosCitaStore();

  const isLoading = ref( false );

  const fecha = ref( {} );

  const cita_fecha = datosCita.get('cita/fecha');
  if ( cita_fecha == '' ) {
    fecha.value = new Date();
  } else {
    fecha.value = cita_fecha;
  }

  let fecha_min = new Date();
  fecha_min.setDate( fecha_min.getDate() + datosCita.get('comercio/minimo_dias'));

  let fecha_max = new Date();
  fecha_max.setDate( fecha_max.getDate() + datosCita.get('comercio/maximo_dias'));


  const hora = ref([{}]);
  hora.value = datosCita.get('cita/hora');

  const profesionales = ref([{}]);
  const horas_disponibles = ref([{}]);


  // params
  const codigo_cuenta = datosCita.get('comercio/codigo_cuenta');
  const comercio_id = datosCita.get('comercio/comercio_id');
  const servicio_id = datosCita.get('cita/servicio_id');
  const servicio_nombre = datosCita.get('cita/servicio_nombre');
  const profesional_id = datosCita.get('cita/profesional_id');
  const profesional_nombre = datosCita.get('cita/profesional_nombre');

  // agrupar profesionales?
  const comercio_seleccion_profesionales = ref( datosCita.get('comercio/seleccion_profesionales') );

  // Fetch data before the component is mounted
  onBeforeMount( () => {
      if ( comercio_seleccion_profesionales.value == 1 ) {
          fetchProfesionales();
      } else {
          const profesional = { profesional_id: datosCita.get('cita/profesional_id'), profesional_nombre: datosCita.get('cita/profesional_nombre')};
          fetchHoras( profesional );
      }
  });

  function getParamFecha ( fecha ) {
    fecha = new Date( fecha );
    const year = fecha.getFullYear();
    const month = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
    const day = fecha.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function setTime ( time, profesional ) {
    hora.value = time;
    datosCita.set('cita/hora', time );
    datosCita.set('cita/profesional_id', profesional.profesional_id );
    datosCita.set('cita/profesional_nombre', profesional.nombre );
    router.push({ name: 'infopersonal' });
  }

  async function fetchProfesionales() {
    try {
      // isLoading.value = true;
      const response = await axios.get( API_URL, {
        timeout: 10000, // 10 segundos
        params: {
          endpoint: 'profesionales',
          codigo_cuenta: codigo_cuenta,
          comercio_id: comercio_id,
          servicio_id: servicio_id
        }
      } );
      if ( Array.isArray(response.data) ) {
        profesionales.value = response.data.map( ({ profesional_id, nombre, foto }) => ({ profesional_id, nombre, foto }) );
        for (const item of profesionales.value) {
          await fetchHoras( item );
        }
      }
      // isLoading.value = false;
    } catch (error) {
      console.log(error);
      // isLoading.value = false;
      if (error.code == "ECONNABORTED") {
        alert(
          "Se ha excedido el tiempo de espera por el servidor."
        );
      }
    }
  }

  async function fetchHoras (profesional) {
      const now = new Date();
      const minimo_horas = datosCita.get('comercio/minimo_horas');
      try {
        // isLoading.value = true;
        const response = await axios.get( API_URL, {
          timeout: 10000, // 10 segundos
          params: {
            endpoint: 'horas',
            codigo_cuenta: codigo_cuenta,
            comercio_id: comercio_id,
            servicio_id: servicio_id,
            fecha: getParamFecha( fecha.value ),
            cita_id: datosCita.get('cita/cita_id'),   // for actualizarcita
            profesional_id: profesional.profesional_id
          }
        } );
        if ( Array.isArray(response.data) ) {
          //profesional.horas = response.data.map( ({ servicio_id, profesional_id, puesto_id, fecha, hora }) => ({ servicio_id, profesional_id, puesto_id, fecha, hora }) );
          horas_disponibles.value = response.data.map( ({ servicio_id, profesional_id, puesto_id, fecha, hora }) => ({ servicio_id, profesional_id, puesto_id, fecha, hora }) );
          if ( minimo_horas > 0 ) {
            // filter hours array to get rid of too-soon ones
            horas_disponibles.value = horas_disponibles.value.filter( ( item ) => {
              let hora_disponible = new Date(`${getParamFecha(item.fecha.date)}T${item.hora}`);
              let diff = (hora_disponible - now) / (1000 * 60 * 60); // Difference in hours
              return diff >= minimo_horas;
            });
          }
          if ( profesional.profesional_id != '' ) profesional.horas = horas_disponibles.value;
          // isLoading.value = false;
          router.push({ name: 'horas' });
          return;
        } else {

          /*** temp data****/
          horas_disponibles.value = [];
          profesional.horas = [];
          /*** /temp ****/
          // isLoading.value = false;

        }

        // isLoading.value = false;

      } catch (error) {

        console.log(error);
        // isLoading.value = false;

        if (error.code == "ECONNABORTED") {
          alert(
            "Se ha excedido el tiempo de espera por el servidor."
          );
        }

      }

  }
  
</script>

<style scoped>
 
ion-datetime.datetime {
  --background: #ffffff;
}



ion-avatar {
    --border-radius: 10px;
    padding-bottom: 10px;
  }

.profesional-item {
    margin: 10px 0;
}

.profesional ion-label {
  font-size: 18px;
  font-weight: bold;
  margin-left: 20px;
}

ion-avatar {
  width: 80px;
  height: 80px;
}

.calendar-day {
  cursor: pointer;
}

.horas {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.horas li {
  padding: 5px;
}



.hora-item ion-button {
  --padding-start: 2em;
  --padding-end: 2em; 
  font-weight: bold;
  font-size: 14px;
}

</style>
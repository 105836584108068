
// global constants & configuration values

//export const API_URL = 'http://213.96.44.220:8099/api/citasEndpoint.php';
export const API_URL = 'https://agenda.optiplus.es/api/citasEndpoint.php';
export const GEOCODE_URL = 'https://geocode.maps.co/search?q=';
export const GOOGLEMAPS_URL = 'https://www.google.com/maps/search/?api=1&query=';


export const DEFAULT_UI =  {
    logo: '/img/logo-optiplus.png',
    title: 'Cita Previa',
    url_home: '/',
    color_interfaz_1: '024105200',
    color_interfaz_2: '233243255',
    color_interfaz_3: '223224225',
    custom_ui: 0,
};






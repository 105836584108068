<template>
    <div class="servicios">
      <GruposComponent />
    </div>
  </template>
  
  <script>
  
  import GruposComponent from '@/components/GruposComponent.vue'
  
  export default {
    name: 'ServiciosView',
    components: {
      GruposComponent
    }
  }
  </script>
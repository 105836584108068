<template>
    <div v-if="errorEncontrarCita == 1">
        <div>
            <!-- <p class="no-encontro">No se encontró la cita</p> -->
            <!-- <ion-note color="warning">No se encontró la cita</ion-note> -->
            <ion-card class="no-encontrada">
                <ion-card-header>
                    <ion-card-title class="no-encontrada-title">Cita no encontrada</ion-card-title>
                    <!-- <ion-card-subtitle>No fue posible encontrar la cita</ion-card-subtitle> -->
                </ion-card-header>

                <ion-card-content class="no-encontrada-content">
                    Es posible que la URL introducida no sea la correcta o que la cita haya sido borrada.
                </ion-card-content>
            </ion-card>
        </div>
    </div>
    <div v-if="citaCargada == 1">
        <div>
            <p v-if="!citaCargada">Consultando datos de su cita...</p>
        </div>

        <div v-if="citaCargada">
            <div class="cita-status" :class="cita_status.color">
                {{cita_status.message}}
            </div>
            <InfoCitaComponent :mode="mode_infocita" />
        </div>

        <div class="action-buttons" v-if="cita_status.status !== 'cancelada'">
            <ion-button expand="block"  fill="outline" @click="cancelCita">Cancelar cita</ion-button>
        </div>
    </div>

    <IonLoading
        :isOpen="isLoading"
        message="Cargando cita..."
        />
</template>
  
<script setup>
/* eslint-disable */
    import { ref, onBeforeMount, onBeforeUnmount, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import { useDatosCitaStore } from '@/store/datosCita' // Pinia store
    import InfoCitaComponent from '@/components/InfoCitaComponent.vue' 
    import axios from 'axios'
    import { DEFAULT_UI } from '@/store/config'
    import { API_URL } from '@/store/config'
    import { setUi } from '@/plugins/opttools'
    import { IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonIcon, IonLoading, IonNote } from '@ionic/vue'
    import { informationCircle, timeOutline, pricetagOutline } from 'ionicons/icons'

    const isLoading = ref(false);

    const router = useRouter();
    const route = router.currentRoute.value;
    const { codigo_cuenta, cita_id, token } = route.params;
    let comercio_id;

    const datosCita = useDatosCitaStore();
    datosCita.set('comercio/codigo_cuenta', codigo_cuenta);

    const comercio = ref({});
    const cita = ref({});

    const requiere_aceptacion = ref(0); 
    const aceptada = ref(0); 
    const cancelada = ref(0);
    const cita_status = ref({ status: '', color: '', message: ''});
    
    const mode_infocita = ref('edit');
    
    const citaCargada = ref(false);
    const comercioCargado = ref(false);

    const errorEncontrarCita = ref(false);
    const errorEncontrarComercio = ref(false);

    const errorAnularCita = ref(false);

    watch(citaCargada, (newValue, oldValue) => {
        isLoading.value = false;
    });
    watch(comercioCargado, (newValue, oldValue) => {
        isLoading.value = false;
    });

    watch(errorEncontrarCita, (newValue, oldValue) => {
        if (newValue == 1) {
            isLoading.value = false;
            router.push({ name: "error404cita", params: { codigo_cuenta, cita_id, token } });
        }
    });
    watch(errorAnularCita, (newValue, oldValue) => {
        if (newValue == 1) {
            isLoading.value = false;
        }
    });
    
    onBeforeMount(() => {
        getCita();
    });

    onBeforeUnmount(() => {
        isLoading.value = false;
    });
    
    async function getCita() {
        try {
            // isLoading.value = true;
            let response = await axios.get( API_URL, {
                timeout: 10000, // 10 segundos
                params: {
                    endpoint: 'consultar_cita',
                    codigo_cuenta: codigo_cuenta,
                    cita_id: cita_id,
                    cita_guid: token
                }
            });
            isLoading.value
            
            if (response && Array.isArray(response.data) ) {
                if (response.data.length == 0) {
                    errorEncontrarCita.value = true;
                    return;
                }
                let cita_actual = response.data[0];
                
                if (cita_actual.aspecto_personalizado == 1) {
                    const custom_ui = {
                        logo: cita_actual.logo_web,
                        title: 'Cita Previa',
                        // url_home: '/' + cita_actual.codigo_cuenta.toLowerCase() + '/' + cita_actual.comercio_id,
                        url_home: '/',
                        color_interfaz_1: cita_actual.comercio_color_interfaz_1,
                        color_interfaz_2: cita_actual.comercio_color_interfaz_2,
                        color_interfaz_3: cita_actual.comercio_color_interfaz_3,
                    }
                    setUi( custom_ui );
                } else {
                    datosCita.sync('ui', DEFAULT_UI );
                    setUi( DEFAULT_UI );
                }

                datosCita.set( 'cita/cita_id', cita_id );
                datosCita.set( 'cita/cita_guid', token );
                datosCita.set( 'cita/codigo_cuenta', codigo_cuenta );
                datosCita.set( 'cita/comercio_id', cita_actual.comercio_id );  
                datosCita.set( 'cita/grupo_id', cita_actual.grupo_id );  
                datosCita.set( 'cita/grupo_nombre', cita_actual.grupo_nombre );
                datosCita.set( 'cita/servicio_id', cita_actual.servicio_id );
                datosCita.set( 'cita/servicio_nombre', cita_actual.servicio_nombre );
                datosCita.set( 'cita/profesional_id', cita_actual.profesional_id );
                datosCita.set( 'cita/profesional_nombre', cita_actual.profesional_nombre );
                datosCita.set( 'cita/fecha', getParamFecha( cita_actual.fecha.date ) );
                datosCita.set( 'cita/hora', getParamHora(cita_actual.tiempo_inicio.date) );
                datosCita.set( 'cita/nombre', cita_actual.nombre );
                datosCita.set( 'cita/apellidos', cita_actual.apellidos );
                datosCita.set( 'cita/telefono', cita_actual.telefono );
                datosCita.set( 'cita/correo_e', cita_actual.correo_e );
                datosCita.set( 'cita/requiere_aceptacion', cita_actual.requiere_aceptacion );
                datosCita.set( 'cita/aceptada', ( cita_actual.aceptada == null ? '' : cita_actual.aceptada ) );
                datosCita.set( 'cita/cancelada', cita_actual.cancelada );

                cita.value = datosCita.get("cita");
            
                requiere_aceptacion.value = cita_actual.requiere_aceptacion;
                aceptada.value = ( cita_actual.aceptada == null ? '' : cita_actual.aceptada );
                cancelada.value = cita_actual.cancelada;
                
                cita_status.value = { status: 'aceptada', color: 'success', message: 'Cita aceptada'};
                
                if ( requiere_aceptacion.value === 1 ) {
                    if ( aceptada.value === 0 ) {
                        cita_status.value = { status: 'rechazada', color: 'danger', message: 'Cita rechazada' };
                    } else {
                        if ( aceptada.value === '' ) cita_status.value = { status: 'pendiente', color: 'warning', message: 'Pendiente de aceptación' };
                    }
                }
                
                if ( cancelada.value === 1 ) {
                    cita_status.value = { status: 'cancelada', color: 'danger', message: 'Cita cancelada' };
                    mode_infocita.value = 'read-only';
                }

                getComercio();
                
                citaCargada.value = true;
                
            } else {
                errorEncontrarCita.value = true;
            }
        } 
        catch (error) {
            console.log(error);
            if (error.code == "ECONNABORTED") {
                alert(
                "Se ha excedido el tiempo de espera por el servidor."
                );
            }
            errorEncontrarCita.value = true;
            isLoading.value
        }
    }

    async function getComercio() {
        try {
            const comercio_id = datosCita.get('cita/comercio_id');
            // isLoading.value = true;
            const response = await axios.get( API_URL, {
                            timeout: 10000, // 10 segundos
                            params: {
                                endpoint: 'comercio',
                                codigo_cuenta: codigo_cuenta,
                                comercio_id: comercio_id ,
                            }
                        } );
            isLoading.value
            if (response && Array.isArray(response.data) && response.data.length > 0) {
                comercio.value = response.data[0];  // just one row expected
                datosCita.sync( 'comercio', comercio.value );
                comercioCargado.value = true;
            } else {
                errorEncontrarComercio.value = true;
            }
        } catch(error) {
            console.log(error);
            if (error.code == "ECONNABORTED") {
                alert(
                "Se ha excedido el tiempo de espera por el servidor."
                );
            }
            errorEncontrarComercio.value = true;
            isLoading.value
        }
    }

    async function cancelCita() {
        if ( !confirm('¿Está seguro de que desea cancelar la cita?') ) return false;
        try {
            isLoading.value = true;
            let response = await axios.get( API_URL, {
            timeout: 10000, // 10 segundos
            params: {
                endpoint: 'cancelar_cita',
                codigo_cuenta: codigo_cuenta,
                cita_id: cita_id,
                cita_guid: token
            }
            } );
            isLoading.value = false;
            if ( Array.isArray(response.data) ) {
            if ( response.data.length == 0 ) {
                errorAnularCita.value = false;
                return;
            } else {
                cita_status.value = { status: 'cancelada', color: 'danger', message: 'Cita cancelada' };
                mode_infocita.value = 'read-only';

                // ok, send mail and go

                // email template management
                const template = 'mail_cita_cancelada';
                
                /*** email sending */
                
                // ... cita ...
                const cita_id = datosCita.get( 'cita/cita_id' );
                const cita_guid = datosCita.get( 'cita/cita_guid' );
                const cita_servicio_nombre = datosCita.get('cita/servicio_nombre' );
                const cita_profesional_nombre = datosCita.get('cita/profesional_nombre' );
                const cita_fecha = datosCita.get('cita/fecha' );
                const cita_hora = datosCita.get('cita/hora' );
                const cita_nombre = datosCita.get('cita/nombre' );
                const cita_apellidos = datosCita.get('cita/apellidos' );
                const cita_telefono = datosCita.get('cita/telefono' );
                const cita_correo_e = datosCita.get('cita/correo_e' );

                // ... comercio ...
                const comercio_nombre = datosCita.get('comercio/nombre');
                const comercio_direccion = datosCita.get('comercio/direccion');
                const comercio_localidad = datosCita.get('comercio/localidad');
                const comercio_telefono = datosCita.get('comercio/telefono');
                const comercio_telefono2 = datosCita.get('comercio/telefono2');

                const ui_logo = datosCita.get('ui/logo');
                
                isLoading.value = true;
                response = await axios.get( API_URL, {
                    timeout: 10000, // 10 segundos
                    params: {
                    endpoint: 'enviar_email',
                    to: cita_correo_e,
                    data: `codigo_cuenta=${codigo_cuenta}|cita_id=${cita_id}|cita_guid=${cita_guid}|nombre=${cita_nombre}|apellidos=${cita_apellidos}|telefono=${cita_telefono}|correo_e=${cita_correo_e}|fecha=${cita_fecha}|hora=${cita_hora}|servicio=${cita_servicio_nombre}|profesional=${cita_profesional_nombre}|comercio_nombre=${comercio_nombre}|comercio_direccion=${comercio_direccion}|comercio_localidad=${comercio_localidad}|comercio_telefono=${comercio_telefono}|comercio_telefono2=${comercio_telefono2}|ui_logo=${ui_logo}`,
                    template: template, // TODO: customize
                    }

                } );
                isLoading.value = false;

                if ( typeof response.data === 'object' ) {

                    // hacemos algo?
                    // isLoading.value

                }
                /*** /email sending */
            }
            } else {
                errorAnularCita.value = false;
                return;
            }
        } catch (error) {
            console.log(error);
            if (error.code == "ECONNABORTED") {
                alert(
                    "Se ha excedido el tiempo de espera por el servidor. Su cita no ha sido cancelada."
                    );
                }    
                isLoading.value = false;
        }
    }

    function getParamFecha ( fecha ) {
        fecha = new Date( fecha );
        const year = fecha.getFullYear();
        const month = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
        const day = fecha.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function getParamHora( fecha ) {
        fecha = new Date( fecha );
        const hours = fecha.getHours().toString().padStart(2, '0');
        const minutes = fecha.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

</script>
  
<style scoped>
  header img.logo {
    display: none;
  }
  .datos-cita {
    margin-bottom: 40px;
  }
  
  .cita-status {
    border-radius: 10px;
    margin: 20px 0;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 20px 40px;
    color: #ffffff;
  }
  
  .cita-status.success {
    background: var(--ion-color-success);
    
  }
  
  .cita-status.danger {
    background: var(--ion-color-danger);
  }
  
  .cita-status.warning {
    background: var(--ion-color-warning);
  }
  
  .no-encontrada {
    text-align: center;
    font-size: large;
    background-color: var(--ion-color-danger);
  }
  .no-encontrada-title {
    font-size: larger;
    color:whitesmoke;
    font-weight: bold;
  }
  .no-encontrada-content {
    font-size: large;
    color:whitesmoke;
    /* background-color: var(--ion-color-danger); */
  }
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { IonicVue } from '@ionic/vue'


/* Optional CSS IONIC */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import '@/assets/css/styles.css'



// app
const app = createApp(App)

// ionic
app.use(IonicVue);

// store
app.use( createPinia() );



// router
app.use(router);

router.isReady().then(() => {
    app.mount('#app');
});


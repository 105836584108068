<template>
  <InfoCitaComponent />

  <div class="message-box">
    <ion-icon :icon="informationCircle"></ion-icon>
    <p>
      Complete su información personal para confirmar la reserva. Todos los datos son
      obligatorios.
    </p>
  </div>

  <div class="form-info-personal">
    <form>
      <ion-list class="infopersonal-fields">
        <ion-item>
          <ion-input
            id="nombre"
            class="infopersonal-field"
            v-model="nombre"
            @ionBlur="validate"
            type="text"
            label="Nombre"
            label-placement="stacked"
            placeholder="Introduzca su nombre..."
            error-text="El nombre no es válido"
          >
          </ion-input>
        </ion-item>

        <ion-item>
          <ion-input
            id="apellidos"
            class="infopersonal-field"
            v-model="apellidos"
            @ionBlur="validate"
            type="text"
            label="Apellidos"
            label-placement="stacked"
            placeholder="Introduzca sus apellidos..."
            error-text="Los apellidos no son válidos"
          >
          </ion-input>
        </ion-item>

        <ion-item>
          <ion-input
            id="telefono"
            class="infopersonal-field"
            v-model="telefono"
            @ionBlur="validate"
            type="tel"
            label="Teléfono"
            label-placement="stacked"
            placeholder="Introduzca su teléfono, preferentemente móvil..."
            error-text="Es necesario un teléfono válido de contacto, sólo números y el prefijo de país si es necesario"
          >
          </ion-input>
        </ion-item>

        <ion-item>
          <ion-input
            id="correo_e"
            class="infopersonal-field"
            v-model="correo_e"
            @ionBlur="validate"
            type="email"
            label="Email"
            label-placement="stacked"
            placeholder="Introduzca su email de contacto..."
            error-text="Es necesario un email válido de contacto"
          >
          </ion-input>
        </ion-item>
      </ion-list>

      <div :class="{ 'form-validation-message': true, hidden: !errorDeFormato }">
        <p>
          Por favor, complete correctamente todos los datos de contacto. Son imprescindibles para poder
          reservar su cita.
        </p>
      </div>

      <div class="politica-privacidad">
        <ion-checkbox v-model="acepto_privacidad" label-placement="end"> </ion-checkbox>
        Acepto la
        <span id="showModal" class="btn-politica-privacidad" @click="showModal"
          >política de privacidad</span
        >
      </div>

      <ion-modal ref="modal" trigger="showModal">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click="cancel()" fill="outline" shape="round"
                >Cerrar</ion-button
              >
            </ion-buttons>
            <ion-title>Política de Privacidad</ion-title>
            <ion-buttons slot="end">
              <ion-button :strong="true" @click="confirm()" fill="solid" shape="round"
                >Aceptar</ion-button
              >
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <PoliticaPrivacidadModalComponent class="politica-privacidad-modal" />
        </ion-content>
      </ion-modal>

      <div class="action-buttons">
        <ion-button expand="block" size="large" @click="submitForm"
          >Confirmar cita</ion-button
        >
      </div>

      <div :class="{ 'form-validation-message': true, hidden: !errorAlInsertarCita }">
        <p>
          Sus datos se han enviado pero el servidor ha rechazado la reserva de cita. Puede
          intentarlo con otros datos o en otro momento. Disculpe las molestias.
        </p>
      </div>
      <div :class="{ 'form-validation-message': true, hidden: !errorAlEnviarCorreo }">
        <p>
          Sucedió un error al tratar de enviarle el correo electrónico de confirmación. Disculpe las molestias.
        </p>
      </div>
    </form>
  </div>

  <IonLoading :isOpen="isLoading" message="Guardando cita..." />
</template>

<script setup>
/* eslint-disable */

// Framework & Tools
import { ref, onBeforeMount, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { useDatosCitaStore } from "@/store/datosCita"; // Pinia store

import InfoCitaComponent from "@/components/InfoCitaComponent.vue";
import PoliticaPrivacidadModalComponent from "@/components/PoliticaPrivacidadModalComponent.vue";

import axios from "axios";

import { isValidParam } from "@/plugins/opttools";

// API
import { API_URL } from "@/store/config";

// UX
import {
  IonItem,
  IonInput,
  IonCheckbox,
  IonList,
  IonButton,
  IonIcon,
  IonLoading,
  IonModal,
  IonButtons,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
} from "@ionic/vue";
import { informationCircle } from "ionicons/icons";

const router = useRouter();
const datosCita = useDatosCitaStore();

const isLoading = ref(false);

// params

const codigo_cuenta = datosCita.get("comercio/codigo_cuenta");
const comercio_id = datosCita.get("comercio/comercio_id");
const comercio_confirmacion_manual = ref(datosCita.get("comercio/confirmacion_manual"));
const comercio_seleccion_profesionales = ref(
  datosCita.get("comercio/seleccion_profesionales")
);

const cita_id = datosCita.get("cita/cita_id");
const cita_guid = datosCita.get("cita/cita_guid");

const servicio_id = datosCita.get("cita/servicio_id");
const servicio_nombre = datosCita.get("cita/servicio_nombre");
const profesional_id = datosCita.get("cita/profesional_id");
const profesional_nombre = datosCita.get("cita/profesional_nombre");
const fecha = datosCita.get("cita/fecha");
const hora = datosCita.get("cita/hora");

// form
const nombre = ref(datosCita.get("cita/nombre"));
const apellidos = ref(datosCita.get("cita/apellidos"));
const telefono = ref(datosCita.get("cita/telefono"));
const correo_e = ref(datosCita.get("cita/correo_e"));

const acepto_privacidad = ref(false);

const modal = ref();
const input = ref();

const cancel = () => modal.value.$el.dismiss(null, "cancel");

const confirm = () => {
  acepto_privacidad.value = true;
  modal.value.$el.dismiss(null, "cancel");
};

const errorDeFormato = ref(false);
const errorAlInsertarCita = ref(false);
const errorAlEnviarCorreo = ref(false);

const mailRespuesta = ref("");

const irAComercios = { name: 'comercios', 
                        params: { codigo_cuenta: codigo_cuenta.toLowerCase() }}

const irAGrupos = { name: 'servicios', 
                    params: { codigo_cuenta: codigo_cuenta.toLowerCase(), 
                              comercio_id: comercio_id,
                              page: 'grupo' }}

const irADia = { name: 'fechas', 
                  params: { codigo_cuenta: codigo_cuenta.toLowerCase(), 
                            comercio_id: comercio_id,
                            page: 'dia' }}

if (codigo_cuenta == "" || comercio_id == "") router.push(irAComercios);
if (servicio_id == "") router.push(irAGrupos);
if (fecha == "" || hora == "") router.push(irADia);

const submitForm = async () => {
  // si el formato no es correcto, sale
  if (  !validateInputFormat( "nombre", nombre.value ) || 
        !validateInputFormat( "apellidos", apellidos.value ) || 
        !validateInputFormat( "telefono", telefono.value ) || 
        !validateInputFormat( "correo_e", correo_e.value ) ) {
    errorDeFormato.value = true;
    return;
  }
  // si no se aceptó la política de privacidad, sale
  if (!acepto_privacidad.value) {
    alert("Debe aceptar la política de privacidad.");
    return;
  }
  isLoading.value = true;

  // toma los parámetros para la cita
  let cita_params = {};
  if (cita_guid != "" && cita_id != "") {
    // update
    cita_params = {
      endpoint: "actualizar_cita",
      codigo_cuenta: codigo_cuenta,
      cita_id: cita_id,
      cita_guid: cita_guid,
      servicio_id: servicio_id,
      profesional_id: profesional_id,
      fecha: fecha,
      hora: hora,
      nombre: nombre.value,
      apellidos: apellidos.value,
      telefono: telefono.value,
      correo_e: correo_e.value,
    };
  } else {
    // insert
    cita_params = {
      endpoint: "insertar_cita",
      codigo_cuenta: codigo_cuenta,
      comercio_id: comercio_id,
      servicio_id: servicio_id,
      profesional_id: profesional_id,
      fecha: fecha,
      hora: hora,
      nombre: nombre.value,
      apellidos: apellidos.value,
      telefono: telefono.value,
      correo_e: correo_e.value,
    };
  }
  // llama al endpoint para insertar la cita
  let respuestaCita;
  try {
    respuestaCita = await axios.get( API_URL, {
      timeout: 10000, // 10 segundos
      params: cita_params,
    });
  } catch (error) {
    isLoading.value = false;
    errorAlInsertarCita.value = true;

    if (error.code == "ECONNABORTED") {
      alert(
        "Se ha excedido el tiempo de espera por el servidor. Su cita no ha sido guardada."
      );
    }
    return;
  }

  const respuestaCorrecta = respuestaCita && Array.isArray(respuestaCita.data) && respuestaCita.data.length > 0;
  
  if (!respuestaCorrecta) {
    if (!Array.isArray(respuestaCita.data)) {
      // imprime por consola los datos devueltos para ver el error
      console.log({ error: respuestaCita.data });
    }
    errorAlInsertarCita.value = true;
    isLoading.value = false;
    return;
  }

  if (respuestaCorrecta) {
    const reserva_cita = respuestaCita.data.map(({ cita_id, cita_guid, exito }) => ({
      cita_id,
      cita_guid,
      exito,
    }));

    let reservaCitaExito = reserva_cita[0].exito;

    // si la cita se insertó, toma los datos
    if (reservaCitaExito) {
      // toma los datos
      datosCita.set("cita/cita_id", reserva_cita[0].cita_id);
      datosCita.set("cita/cita_guid", reserva_cita[0].cita_guid);
      datosCita.set("cita/nombre", nombre.value);
      datosCita.set("cita/apellidos", apellidos.value);
      datosCita.set("cita/telefono", telefono.value);
      datosCita.set("cita/correo_e", correo_e.value);
      datosCita.set("cita/fecha", fecha);
      datosCita.set("cita/hora", hora);
      datosCita.set("cita/servicio_id", servicio_id);
      datosCita.set("cita/servicio_nombre", servicio_nombre);
      datosCita.set("cita/profesional_id", profesional_id);
      datosCita.set("cita/profesional_nombre", profesional_nombre);

      if (comercio_seleccion_profesionales == 0) profesional_nombre = "No facilitado";

    } else {
      errorAlInsertarCita.value = true;
      isLoading.value = false;
      return;
    }

    // si la cita se insertó, envía el email
    if (reservaCitaExito) {
      // email template management
      let template = "mail_cita";
      if (cita_params.endpoint == "actualizar_cita") {
        template += "_modificada";
      }
      if (comercio_confirmacion_manual.value == 1) {
        template += "_pendiente_confirmacion";
      } else {
        template += "_confirmada";
      }

      /*** email sending */
      const comercio_nombre = datosCita.get("comercio/nombre");
      const comercio_direccion = datosCita.get("comercio/direccion");
      const comercio_localidad = datosCita.get("comercio/localidad");
      const comercio_telefono = datosCita.get("comercio/telefono");
      const comercio_telefono2 = datosCita.get("comercio/telefono2");
      const ui_logo = datosCita.get('ui/logo');

      // llama al endpoint para enviar el email
      let respuestaEmail;
      try {

        if (correo_e.value === "fallo@fallo.es") {
          throw new Error("fallo@fallo.es");
        };

        respuestaEmail = await axios.get(API_URL, {
          timeout: 10000, // 10 segundos
          params: {
            endpoint: "enviar_email",
            to: correo_e.value,
            data: `codigo_cuenta=${codigo_cuenta}|cita_id=${reserva_cita[0].cita_id}|cita_guid=${reserva_cita[0].cita_guid}|nombre=${nombre.value}|apellidos=${apellidos.value}|telefono=${telefono.value}|correo_e=${correo_e.value}|fecha=${fecha}|hora=${hora}|servicio=${servicio_nombre}|profesional=${profesional_nombre}|comercio_nombre=${comercio_nombre}|comercio_direccion=${comercio_direccion}|comercio_localidad=${comercio_localidad}|comercio_telefono=${comercio_telefono}|comercio_telefono2=${comercio_telefono2}|ui_logo=${ui_logo}`,
            template: template, // TODO: customize
          },
        });
        
      } catch (error) {
        console.log(error);
        isLoading.value = false;
        errorAlEnviarCorreo.value = true;
      }

      let envioEmailExito = respuestaEmail && typeof respuestaEmail.data === "object";
      console.log({data: respuestaEmail.data});


      // si el endpoint respondió
      if (envioEmailExito) {

        const email_sent = respuestaEmail.data.map(({ status, message, mail }) => ({
          status,
          message,
          mail,
        }));

        mailRespuesta.value = respuestaEmail.data.mail;
        
      } else {
        // TODO: mejorar feedback
        errorAlEnviarCorreo.value = true;
        isLoading.value = false;
      }
    }
  }
  isLoading.value = false;

  const irAResumen = { name: 'resumen', 
                      params: { codigo_cuenta: codigo_cuenta.toLowerCase(), 
                                comercio_id: comercio_id }}

  router.push(irAResumen);
};





function validate(input) {

  const id = input.target.id;
  const value = input.target.value;

  input.target.classList.add("ion-touched");

  if ( validateInputFormat( id, value ) ) {
    input.target.classList.remove("ion-invalid");
    input.target.classList.add("ion-valid");
  } else {
    input.target.classList.remove("ion-valid");
    input.target.classList.add("ion-invalid");
  }

}

function validateInputFormat( id, value ) {

  var valid = true;
  var regExp = "";

  if (value === "") { // validacion básica

    valid = false;

  } else {

  // validaciones de formato personalizadas para nombre, teléfono, email...
  switch( id ){

    case "nombre":
    case "apellidos":
      if ( value.length > 2 ) {
        regExp = /^[A-Za-zÁáÉéÍíÓóÚúÑñÜü\s\-_']+$/;
        valid = regExp.test( value );
      } else {
        valid = false;
      }
      break;

    case "telefono":
      regExp = /^\+?[0-9\s-]+$/;
      valid = regExp.test( value );
      break;

    case "correo_e":
      regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      valid = regExp.test( value );
      break;
  }

  return valid;

}



}

onBeforeMount(() => {
  isLoading.value = false;
});

onBeforeUnmount(() => {
  isLoading.value = false;
});

</script>

<style scoped>
.infopersonal-fields {
  margin-bottom: 40px;
}

ion-input.infopersonal-field {
  --border-width: 0;
}

ion-checkbox {
  --border-width: 1px;
  --border-radius: 3px;
  --border-color-checked: var(--op-color-primary);
  margin-bottom: -4px;
  margin-right: 5px;
}

.form-validation-message {
  display: block;
  padding: 10px 20px;
  color: var(--ion-color-danger);
  font-size: 14px;
  line-height: 20px;
}

.politica-privacidad {
  text-align: center;
}

.btn-politica-privacidad {
  color: var(--op-color-primary);
  cursor: pointer;
  display: inline-block;
}

.hidden {
  display: none;
}

ion-title {
  --color: var(--op-color-primary);
  text-align: center;
}

ion-toolbar {
  --background: var(--op-color-secondary);
}
</style>

<template>

  <h1>{{ cuenta.nombre_comercial }}</h1>

  <ul class="cards comercios">
    <li
      v-for="comercio in paginatedComercios"
      :key="comercio.nombre"
      @click="goToComercio(comercio.codigo_cuenta, comercio.comercio_id)"
    >
      <div class="title">{{ comercio.nombre }}</div>
      <div class="subtitle">{{ comercio.localidad }}</div>
      <div class="direccion">{{ comercio.direccion }}</div>
      <div class="description" v-if="comercio.distancia_m > 0">
        {{ mToKm(comercio.distancia_m) }} km
      </div>
    </li>
  </ul>

  <p class="buscando" v-if="comercios != null && comercios.length == 0">
    Buscando ópticas...
  </p>

  <div class="action-buttons">
    <ion-button
      fill="outline"
      expand="block"
      class="btn-loadmore"
      @click="loadMore"
      v-if="showLoadMoreButton && comercios.length > 0"
      >Cargar más...</ion-button
    >
  </div>

  <IonLoading :isOpen="isLoading" message="Cargando..." />
</template>

<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useDatosCitaStore } from "@/store/datosCita";
import axios from "axios";
import { setUi, isValidParam } from "@/plugins/opttools";
import { API_URL, DEFAULT_UI } from "@/store/config";
import { IonButton, IonLoading } from "@ionic/vue";

const router = useRouter();
const { codigo_cuenta = "" } = router.currentRoute.value.params;

const datosCita = useDatosCitaStore();
datosCita.set("comercio/codigo_cuenta", codigo_cuenta.toLowerCase());

const cuenta = ref({});
const comercios = ref([]);

const coords = ref({ latitud: "", longitud: "" });
const max_resultados = 100;
const itemsPerPage = 25;
const currentPage = ref(0);

const isLoading = ref(false);

const paginatedComercios = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return comercios.value.slice(0, endIndex);
});

const showLoadMoreButton = computed(() => {
  return currentPage.value * itemsPerPage < comercios.value.length;
});

onBeforeMount(() => {
  sessionStorage.clear();
  sessionStorage.setItem('entrada_general', 0);

  if (!isValidParam(codigo_cuenta) || codigo_cuenta.length !== 4)  {
    // isLoading.value = false;
    router.push({ name: "error404cuenta", params: { codigo_cuenta } });
    return;
  }

  // isLoading.value = false;
  fetchCuenta();
});

function loadMore() {
  currentPage.value++;
}

async function fetchCuenta() {
  try {
    if ( !isValidParam(codigo_cuenta) ) {
      // isLoading.value = false;
      router.push("/");
      return;
    }
    // isLoading.value = true;
    const response = await axios.get(API_URL, {
      timeout: 10000, // 10 segundos
      params: {
        endpoint: "cuenta",
        codigo_cuenta: codigo_cuenta,
      },
    });
    // isLoading.value = false;
    if (Array.isArray(response.data)) {
      cuenta.value = response.data[0];
      if (cuenta.value.enlace_directo != 1) {
        datosCita.$reset();
        datosCita.sync("ui", DEFAULT_UI);
        setUi(DEFAULT_UI);
        router.push("/");
        return;
      } else {
        const custom_ui = {
          logo: cuenta.value.logo_web,
          title: "Cita Previa",
          url_home: "/" + codigo_cuenta.toLowerCase(),
          color_interfaz_1: cuenta.value.color_interfaz_1,
          color_interfaz_2: cuenta.value.color_interfaz_2,
          color_interfaz_3: cuenta.value.color_interfaz_3,
          custom_ui: 1,
        };
        setUi(custom_ui);
        datosCita.sync("ui", custom_ui);
        datosCita.set("comercio/codigo_cuenta", codigo_cuenta.toLowerCase());
        geocodeBrowser();
      }
    } else {
      router.push({ name: "error404cuenta", params: { codigo_cuenta } });
    }
  } catch (error) {
    console.log(error);
    // isLoading.value = false;
    if (error.code == "ECONNABORTED") {
      alert("Se ha excedido el tiempo de espera por el servidor.");
    }
    router.push({ name: "error404cuenta", params: { codigo_cuenta } });
  }
}

function geocodeBrowser() {
  clearCoords();
  if ("geolocation" in navigator) {
    // isLoading.value = true;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        coords.value.latitud = position.coords.latitude;
        coords.value.longitud = position.coords.longitude;
        // isLoading.value = false;
        fetchComercios();
      },
      (error) => {
        // isLoading.value = false;
        console.log("GeoLocation:", error);
        fetchComercios();
      }
    );
    // isLoading.value = false;
  }
}

function clearCoords() {
  coords.value.latitud = null;
  coords.value.longitud = null;
}

function mToKm(m) {
  if (isNaN(m)) m = 0;
  const km = m / 1000;
  return km.toFixed(2);
}

async function fetchComercios() {
  try {
    if (coords.value.latitud == "" && coords.value.longitud == "") {
      coords.value = { latitud: null, longitud: null }; //default???
    }
    // // isLoading.value = true;
    const response = await axios.get(API_URL, {
      timeout: 10000, // 10 segundos
      params: {
        endpoint: "comercios",
        codigo_cuenta: codigo_cuenta,
        texto: "",
        latitud: coords.value.latitud,
        longitud: coords.value.longitud,
        max_resultados: max_resultados,
      },
    });
    // isLoading.value = false;
    if (Array.isArray(response.data)) {
      comercios.value = response.data;
    } else {
      comercios.value = [];
    }
    currentPage.value = 1;
  } catch (error) {
    console.log(error);
    // isLoading.value = false;
    if (error.code == "ECONNABORTED") {
      alert("Se ha excedido el tiempo de espera por el servidor.");
    }
  }
  if (comercios.value != null && comercios.value.length == 0) {
    router.push({ name: "error404cuenta", params: { codigo_cuenta } });
  }
}

function goToComercio(codigo_cuenta, comercio_id) {
  datosCita.set("comercio/codigo_cuenta", codigo_cuenta.toLowerCase());
  datosCita.set("comercio/comercio_id", comercio_id);
  const irAComercio = { name: 'comercio', 
                        params: { codigo_cuenta: codigo_cuenta.toLowerCase(), 
                                  comercio_id: comercio_id }}
  router.push(irAComercio)
}
</script>

<style scoped>
ul {
  text-align: left;
}

.buscando {
  text-align: center;
  font-style: italic;
}

.action-buttons {
  flex-direction: column;
  margin-bottom: 40px;
}
</style>

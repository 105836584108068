<template>
  <div class="comercio">
    <ComercioComponent />
  </div>
</template>

<script>

import ComercioComponent from '@/components/ComercioComponent.vue'

export default {
  name: 'ComercioView',
  components: {
    ComercioComponent
  }
}
</script>

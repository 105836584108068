<template>
  <div class="comercios-cuenta">
    <CuentaComponent />
  </div>
</template>

<script>

import CuentaComponent from '@/components/CuentaComponent.vue'

export default {
  name: 'CuentaView',
  components: {
    CuentaComponent
  }
}
</script>

<template>
  <div class="resumen-container">

    <h1 v-if="comercio_confirmacion_manual">Su cita ha sido solicitada con éxito, pero requiere la aceptación de la óptica</h1>
    <h1 v-if="!comercio_confirmacion_manual">Su cita ha sido reservada con éxito</h1>
    
    <div class="message-box">
      <ion-icon :icon="informationCircle"></ion-icon>
      <p>Estos son los datos de su cita. Se ha enviado un correo electrónico a la dirección proporcionada con toda la información y un enlace para comprobar el estado de la cita, modificarla o anularla.</p>
    </div>

    <InfoCitaComponent :mode="read-only" />

    <p class="estado-aceptacion" v-if="comercio_confirmacion_manual">Su cita se encuentra pendiente de aceptación por parte de la óptica</p>
 
    <div class="action-buttons">
      <ion-button expand="block"  fill="outline" @click="otraOptica">Pedir otra cita</ion-button>
    </div>

  </div>
</template>

<script setup>
  /* eslint-disable */
  import { ref, onBeforeMount } from 'vue'
  import { useRouter } from 'vue-router'
  import { useDatosCitaStore } from '@/store/datosCita' // Pinia store
  import InfoCitaComponent from '@/components/InfoCitaComponent.vue'
  import { isValidParam } from '@/plugins/opttools'
  import { IonItem, IonInput, IonList, IonButton, IonIcon } from '@ionic/vue'
  import { informationCircle } from 'ionicons/icons'

  const router = useRouter();
  const datosCita = useDatosCitaStore();

  const codigo_cuenta = datosCita.get('comercio/codigo_cuenta');
  const comercio_id = datosCita.get('comercio/comercio_id');
  const servicio_nombre = datosCita.get('cita/servicio_nombre');
  const profesional_nombre = datosCita.get('cita/profesional_nombre');
  const fecha = datosCita.get('cita/fecha');
  const hora = datosCita.get('cita/hora');
  const nombre = datosCita.get('cita/nombre');
  const apellidos = datosCita.get('cita/apellidos');
  const telefono = datosCita.get('cita/telefono');
  const correo_e = datosCita.get('cita/correo_e');

  const requiere_aceptacion = ref( datosCita.get('cita/requiere_aceptacion'));
  const aceptada = ref(datosCita.get('cita/aceptada')); 
  const cancelada = ref(datosCita.get('cita/cancelada')); 

  const comercio_confirmacion_manual = ref(datosCita.get('comercio/confirmacion_manual'));

  const urlHome = ref(datosCita.get('ui/url_home'));
  
  function otraOptica() {
    router.push({ name: 'comercio', params: { codigo_cuenta: codigo_cuenta.toLowerCase(), comercio_id }});
  }
</script>

<style scoped>
  .datos-cita {
    margin-bottom: 40px;
  }

  .estado-aceptacion {
    font-weight: bold;
    text-align: center;
  }
</style>

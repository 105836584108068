<template>
  <div class="home-welcome">
    <div class="hello" v-if="ui_personalizado != '1'">
      <h1>Bienvenido a nuestro sistema de reserva de citas para ópticas</h1>
      <p>Pulse el botón para empezar el proceso.</p>
    </div>

    <div class="action-buttons">
      <ion-button expand="block" fill="solid" size="large" @click="empezar">Empezar</ion-button>
    </div>
  </div>
</template>

<script setup>
  import { useDatosCitaStore } from "@/store/datosCita";
  import { useRouter } from "vue-router";
  import { setUi } from "@/plugins/opttools";
  import { IonButton } from "@ionic/vue";
  import { DEFAULT_UI } from "@/store/config";

  const router = useRouter();

  const datosCita = useDatosCitaStore();
  datosCita.sync("ui", DEFAULT_UI);
  setUi(DEFAULT_UI);
  
  sessionStorage.clear();
  sessionStorage.setItem('entrada_general', 1);

  function empezar() {
    router.push({ name: 'comercios' });
  }
</script>

<style scoped>
  h1,
  p {
    text-align: center;
  }
  .hello {
    margin-bottom: 80px;
  }
</style>

<template>
  <div class="fechas">
    <FechasComponent />
  </div>
</template>

<script>

import FechasComponent from '@/components/FechasComponent.vue'

export default {
  name: 'FechasView',
  components: {
    FechasComponent
  }
}
</script>

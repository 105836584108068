<template>
  <div class="comercios">
    <ComerciosComponent />
  </div>
</template>

<script>

import ComerciosComponent from '@/components/ComerciosComponent.vue'

export default {
  name: 'ComerciosView',
  components: {
    ComerciosComponent
  }
}
</script>
